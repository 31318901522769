

#slider .swiper-slide{
    width: 100%;
    /*height: calc(85vh);*/
    height: calc(100vh - 135.5px);
    /*margin-top: 10rem;*/
}

.swiper, .swiper-container {
    width: 100%;
	/*height: 100%;*/
}

.swiper-button-next{
    /*background-image: none;
    right: -20px;
    left: auto;*/
    right: 30px !important;
    outline: none;
    color: var(--white) !important;
}
.swiper-button-prev {
    /*background-image: none;
    left: -20px;
    right: auto;*/
    left: 30px !important;
    outline: none;
    color: var(--white) !important;
}

#slider .swiper-pagination-bullet {
    width: 30px !important;
    height: 6px !important;
    background: var(--white) !important;
    opacity: 0.85 !important;
    margin: 0 4px !important;
    border-radius: 10px;
    border: none;
}

#slider .swiper-pagination-bullet-active {
    background: var(--orange) !important;
    border: none;
}

.swiper-pagination-bullet {
    border: 2px solid #de7612;
}
.swiper-pagination-bullet-active {
    background-color: var(--orange) !important;
    opacity: 1 !important;
    outline: none;
}

/* TESTEMUNHOS */
    #opinion .swiper-pagination-bullet-active, #noticias .swiper-pagination-bullet-active, #insights .swiper-pagination-bullet-active{
        background-color: var(--body);
        /*margin: 0 5px;*/
    }
    #opinion .swiper-pagination-bullet, #noticias .swiper-pagination-bullet, #insights .swiper-pagination-bullet{
        margin: 0 4px;
    }
    #opinion .swiper-pagination-bullet, #noticias .swiper-pagination-bullet, #insights .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 50%;
        background: transparent;
        opacity: 1;
        border: 1px solid #de7612;
        transition: opacity .3s;
    }

/* end TESTEMUNHOS */

.swiper-pagination-cartaz {
    position: absolute;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0,0,0);
    z-index: 10;
}

#slider .swiper-button-next:after,
#slider .swiper-button-prev:after {
	font-size: 40px;
}

/* CAPTIONS */
    .caption_top_center {
        position: absolute;
        top: 25%;
        right: 50%;
        left: 50%;
        width: 75%;
        text-align: center;
        padding: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .caption_top_left {
        position: absolute;
        top: 25%;
        left: 0;
        width: 50%;
        text-align: left;
        -webkit-transform: translate(25%, -50%);
        -moz-transform: translate(25%, -50%);
        -ms-transform: translate(25%, -50%);
        -o-transform: translate(25%, -50%);
        transform: translate(25%, -50%);
    }

    .caption_top_right {
        position: absolute;
        top: 25%;
        left: 0%;
        width: 50%;
        text-align: right;
        -webkit-transform: translate(75%, -50%);
        -moz-transform: translate(75%, -50%);
        -ms-transform: translate(75%, -50%);
        -o-transform: translate(75%, -50%);
        transform: translate(75%, -50%);
    }

    .caption_bottom_left {
        position: absolute;
        /*top: 50%;*/
        bottom: 0;
        left: 0;
        width: 50%;
        text-align: left;
        -webkit-transform: translate(25%, -50%);
        -moz-transform: translate(25%, -50%);
        -ms-transform: translate(25%, -50%);
        -o-transform: translate(25%, -50%);
        transform: translate(25%, -50%);
    }

    .caption_bottom_right {
        position: absolute;
        bottom: 0;
        left: 0%;
        width: 50%;
        text-align: right;
        -webkit-transform: translate(75%, -50%);
        -moz-transform: translate(75%, -50%);
        -ms-transform: translate(75%, -50%);
        -o-transform: translate(75%, -50%);
        transform: translate(75%, -50%);
    }

    .caption_bottom_center {
        position: absolute;
        right: auto;
        bottom: 0;
        left: 50%;
        width: 75%;
        text-align: center;
        padding: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .caption_middle_left {
        position: absolute;
        top: 50%;
        bottom: auto;
        left: 0%;
        width: 50%;
        text-align: left;
        -webkit-transform: translate(25%, -50%);
        -moz-transform: translate(25%, -50%);
        -ms-transform: translate(25%, -50%);
        -o-transform: translate(25%, -50%);
        transform: translate(25%, -50%);
    }

    .caption_middle_right {
        position: absolute;
        top: 50%;
        bottom: auto;
        left: 0%;
        width: 50%;
        text-align: right;
        -webkit-transform: translate(75%, -50%);
        -moz-transform: translate(75%, -50%);
        -ms-transform: translate(75%, -50%);
        -o-transform: translate(75%, -50%);
        transform: translate(75%, -50%);
    }

    .caption_middle_center {
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        width: 75%;
        text-align: center;
        padding: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

.slide {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: 18px;
    background: #fff;
    overflow: hidden;
}

.swiper-pagination{
    bottom: 0;
    right: 0;
    left: 0;
}

/*.slide-image {
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
    position: absolute;
    left: -5%;
    right: 0;
    width: 110%;
    height: 100%;
    background-position: 50% 45%;
    background-size: cover;
}*/

.slide-image {
    /*position: absolute;*/
    right: 0;
    height: 100%;
    background-position: 50% 50%;

    /*width: 110% !important;
    left: -5% !important;
    border-bottom-left-radius: 35%;
    border-bottom-right-radius: 35%;*/
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    will-change: transform;
}
/*.slide-title {
    font-size: 3vw;
    font-weight: 900;
    line-height: 1.3;
    color: var(--white);
    max-width: 50%;
    white-space: normal;
    word-break: break-word;
    text-transform: none;
    text-shadow: rgb(0 0 0 / 40%) 1px 1px 1px;
    z-index: 100;
}*/

.cartaz-titulo, .cartaz-subtitulo {
    color: var(--white);
    font-size: 52px;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 1.2;
    margin: 0;
    margin-bottom: 1rem;
    text-shadow: 1px 2px 2px rgb(51 51 51 / 50%), 0px 0px 40px rgb(0 0 0 / 10%);
    z-index: auto;
    padding: 0px;
    filter: none;
}
.cartaz-subtitulo {
    font-size: 	24px;
    line-height: 1em;
    text-transform: initial;
    font-weight: 600;
    text-shadow: 1px 2px 2px rgb(51 51 51 / 50%), 0px 0px 20px rgb(0 0 0 / 10%);
}
.btn-left{
    position: absolute;
    top: 65%;
    left: 15%;
}


#slider .btn {    
    /* Border styles */
    border: 2px solid transparent;
    background-color: rgb(1 59 90 / 80%);
}
#slider .btn:hover {    
    /* Border styles */
    border: 2px solid transparent;
    background-color: rgb(1 59 90 / 100%);
}

/* BUTTON SCROLL SLIDER */
    .demo {
        position: absolute;
        bottom: 20px;
        left: 50%;
        z-index: 2;
        display: inline-block;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        color: var(--cinza);
        text-decoration: none;
        transition: opacity .3s;
    }
    #slider-btn {
        padding-top: 60px;
    }
    #slider-btn span {
        position: absolute;
        top: 0;
        left: 50%;
        width: 30px;
        height: 50px;
        margin-left: -15px;
        border: 2px solid var(--white);
        border-radius: 50px;
        box-sizing: border-box;
    }
    #slider-btn span::before {
        position: absolute;
        top: 10px;
        left: 50%;
        content: '';
        width: 6px;
        height: 6px;
        margin-left: -3px;
        background-color: var(--white);
        border-radius: 100%;
        -webkit-animation: sdb10 2s infinite;
        animation: sdb10 2s infinite;
        box-sizing: border-box;
    }

    @-webkit-keyframes sdb10 {
        0% {
            -webkit-transform: translate(0, 0);
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        80% {
            -webkit-transform: translate(0, 20px);
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
    @keyframes sdb10 {
        0% {
            transform: translate(0, 0);
            opacity: 0;
        }
        40% {
            opacity: 1;
        }
        80% {
            transform: translate(0, 20px);
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }


/* MEDIA QUERIES */

    @media only screen and (max-width : 1680px) {
        #slider .swiper-slide{
            height: calc(100vh - 151px);
        }
    }
    @media only screen and (max-width : 1180px) {
        #slider .swiper-slide{
            height: calc(100vh - 151px);
        }
    }
    @media only screen and (max-width : 992px) {
        #slider .swiper-slide {
            height: calc(100vh - 596px);
        }
        .cartaz-titulo {
            font-size: 32px;
            line-height: 1;
        }
        .cartaz-subtitulo {
            font-size: 1.75vh;
        }
    }
    @media only screen and (max-width : 768px) {
        #slider .swiper-slide{
            height: calc(100vh - 506px);
        }
        /*.cartaz-titulo {
            font-size: 3vh;
        }*/
        .caption_top_center, .caption_top_left, .caption_top_right,
        .caption_middle_center, .caption_middle_left, .caption_middle_right,
        .caption_bottom_center, .caption_bottom_left, .caption_bottom_right {
            text-align: center;
            position: absolute;
            top: 50%;
            right: auto;
            bottom: auto;
            left: 50%;
            width: 75%;
            text-align: center;
            padding: 0;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        /*.swiper-button-next,
            .swiper-button-prev { 
            display: none !important;;
        }*/
    }
    @media only screen and (max-width : 576px) {}

    @media only screen and (max-width : 375px) {
        #slider .swiper-slide {
            height: calc(100vh - 400px);
        }
    }

    /*.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 3vh !important;
    }*/