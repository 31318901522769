
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");


/*font-family: "Poppins", sans-serif;
font-family: 'Sora', sans-serif;
*/

	:root {
		--main-color: #444444;
		--color-dark: #252c30;
		--text-grey: #606060;
		--blue-dark: #013b5a;
		--blue-light: #7f9dac;
		--blue: #008AC9;
		--orange-dark: #DF760E;
		--orange-light: #EFBA86;
		--orange: #DF760E;
		--black: #282828;
		--white: #FFFFFF;
		--red: #DB001B;
		--green: #6ca66c;
		--yellow: #FFAA00;
		--gray-dark: #111111;
		--gray: #92a1b7;
		--gray-light: #fafafa;
		--body: #444444;

		font-size: 16px;
	}

	html {
		-ms-text-size-adjust: 100%;
    	-webkit-text-size-adjust: 100%;
    }

	body {
		margin: 0;
		font-family: "Poppins", sans-serif;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5715;
		letter-spacing: 0.25px;
		color: var(--main-color);
		cursor: default;
		text-rendering: optimizelegibility;
	}

	*:focus {
		outline: none !important;
		box-shadow: 0 0 0 0 transparent !important;
	}

	::selection {
		background: rgb(1 59 90 / 10%);
	}

	a {
		color: var(--orange-dark);
		overflow-wrap: break-word;
		word-wrap: break-word;
	}
	a:hover {
		color: var(--gray);
	}

	a.color{
		color: var(--blue-dark);
	}
	a.color-red{
		color: var(--red);
	}
	a.color-orange{
		color: var(--orange-dark);
	}

	footer a {
		color: var(--white);
		text-decoration: none;
		display: inline-block;
		/*padding-bottom: 2px;
		margin-bottom: 4px;*/
		border-bottom: rgba(255, 255, 255, 0) solid 1px;
		transition: all .25s ease-out;
	}
	footer a:hover {
        text-decoration: none;
        border-bottom: rgba(255, 255, 255,.5) solid 1px;
	}

	footer a i:hover {
		text-decoration: none;
	}

	b, strong {
		font-weight: 600;
	}


	.div-text ul, .col-text ul, .div-text ol, .col-text ol {
		padding-inline-start: 20px;
		/*margin-bottom: 0.5rem;*/
	}
	.div-text ul li, .col-text ul li, .div-text ol li, .col-text ol li, .div-text ul li ul li, .col-text ul li ul li, .div-text ol li ol li, .col-text ol li ol li, .div-text ul li ol li, .col-text ul li ol li, .div-text ol li ul li, .col-text ol li ul li {
		padding-left: 1rem;
		padding-bottom: 0.25rem;
		/*margin-top: 0.25rem;*/
	}
	.div-text ul li ul li:first-child, .col-text ul li ul li:first-child, .div-text ul li ol li:first-child, .col-text ul li ol li:first-child, .div-text ol li ul li:first-child, .col-text ol li ul li:first-child, .div-text ol li ol li:first-child, .col-text ol li ol li:first-child {
		padding-top: 0.5rem;
	}
	.div-text ul li::marker, .col-text ul li::marker {
		content: "\2756";
		color: var(--blue-light);
	}
	.div-text ul li ul li::marker, .col-text ul li ul li::marker {
		content: "\2666";
		color: var(--orange-dark);
	}
	.div-text ol li::marker, .col-text ol li::marker {
		/*color: var(--blue-light);*/
		font-weight: 600;
	}


	/* MARGIN PADDING */
	    .mt-100 {
	        margin-top: 100px;
	    }
	    .py-100 {
	        padding-top: 100px;
	        padding-bottom: 100px;
	    }
	    #destaque.py-100 {
	        padding-top: 85px;
	        padding-bottom: 85px;
	    }
	/* end MARGIN PADDING */

	.btn {
		font-size: 0.75rem;
		font-weight: 500;
		line-height: 1;
		letter-spacing: 0.75px;
		text-decoration: none;
		border-radius: 40px;
		padding: 10px 20px;
		display: inline-flex;
		justify-content: center;
		align-items: baseline;
		-webkit-transition: all 0.3s ease 0s !important;
		-moz-transition: all 0.3s ease 0s !important;
		transition: all 0.3s ease 0s !important;
	}
	#project .btn, #destaque .btn, #opinion .btn, #newsletter .btn {
		font-weight: 500;
		letter-spacing: 0.75px;
	}
	.btn-primary,
	.btn-primary:not(:disabled):not(.disabled).active,
	.btn-primary:not(:disabled):not(.disabled):active,
	.show>.btn-primary.dropdown-toggle {
	    background-color: var(--blue-dark);
	    border: 2px solid var(--blue-dark);
	    color: var(--white);
	}
	.btn-primary:hover, .btn-primary:active,
	.btn-primary.active, .open > .dropdown-toggle.btn-primary,
	.btn-primary:focus, .btn-primary.focus {
	    background-color: #015b8b;
	    border: 2px solid #015b8b;
	    outline: none;
	    box-shadow: none;
	}
	.btn-primary.disabled, .btn-primary:disabled{
	  background-color: var(--blue-light);
	  border: 2px solid var(--blue-light);
	  color: var(--white);
	}
	.btn-primary:not(:disabled):not(.disabled).active:focus,
	.btn-primary:not(:disabled):not(.disabled):active:focus,
	.show>.btn-primary.dropdown-toggle:focus {
	    box-shadow: 0 0 0 0rem rgba(0,123,255,.5);
	}
	.btn-secondary,
	.btn-secondary:not(:disabled):not(.disabled).active,
	.btn-secondary:not(:disabled):not(.disabled):active,
	.show>.btn-secondary.dropdown-toggle {
	    background-color: #015b8b;
	    border: 2px solid #015b8b;
	    color: var(--white);
	}
	.btn-secondary:hover, .btn-secondary:active,
	.btn-secondary.active, .open > .dropdown-toggle.btn-secondary,
	.btn-secondary:focus, .btn-secondary.focus{
	    color: var(--blue-dark);
	    background-color: var(--white);
	    border: 2px solid var(--blue-dark);
	    outline: none;
	    box-shadow: none;
	}
	.btn-secondary.disabled, .btn-secondary:disabled{
	  background-color: var(--blue-light);
	  border: 2px solid var(--blue-light);
	  color: var(--white);
	}
	.btn-secondary:not(:disabled):not(.disabled).active:focus,
	.btn-secondary:not(:disabled):not(.disabled):active:focus,
	.show>.btn-secondary.dropdown-toggle:focus {
	    box-shadow: 0 0 0 0rem rgba(0,123,255,.5);
	}
	.btn-tertiary,
	.btn-tertiary:not(:disabled):not(.disabled).active,
	.btn-tertiary:not(:disabled):not(.disabled):active,
	.show>.btn-tertiary.dropdown-toggle  {
	    background-color: var(--orange-dark);
	    border: 2px solid var(--orange-dark);
	    color: var(--white);
	}
	.btn-tertiary:hover, .btn-tertiary:active,
	.btn-tertiary.active, .open > .dropdown-toggle.btn-tertiary,
	.btn-tertiary:focus, .btn-tertiary.focus{
		color: var(--white);
	    background-color: var(--orange-light);
	    border: 2px solid var(--orange-light);
	    outline: none;
	    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
	}
	.btn-tertiary.disabled, .btn-tertiary:disabled{
	  background-color: var(--orange-light);
	  border: 2px solid var(--orange-light);
	  color: var(--white);
	}
	.btn-tertiary:not(:disabled):not(.disabled).active:focus,
	.btn-tertiary:not(:disabled):not(.disabled):active:focus,
	.show>.btn-tertiary.dropdown-toggle:focus {
	    box-shadow: 0 0 0 0rem rgba(0,123,255,.5);
	}

	#info .btn-primary {
	    background-color: var(--white);
	    font-weight: 700;
	    border: 2px solid var(--white);
	    color: var(--blue-dark);
	    border-radius: 30px;
	    padding: 5px 20px;
	}
	#info .btn-primary:hover, #info .btn-primary:active,
	#info .btn-primary.active, #info .open > .dropdown-toggle.btn-primary,
	#info .btn-primary:focus, #info .btn-primary.focus{
	    background-color: var(--orange-dark);
	    border-color: var(--orange-dark);
	    outline: none;
	    color: var(--white);
	}

	.btn-link:hover{
	    color: var(--blue-dark);
	    text-decoration: underline;
	}

	button.btn-link:hover,
	button.btn-link:focus{
	    text-decoration: none;
	}

	.btn-text, .btn-text-inv {
		font-weight: 500;
		letter-spacing: 0.75px;
		color: var(--main-color);
		transition: all 0.3s ease 0s;
		background-color: var(--gray-light);
		padding: 7px 14px;
		text-decoration: none;
	}
	.btn-text-inv {
		color: var(--gray-light);
		background-color: var(--main-color);
	}
	.btn-text:hover {
		color: var(--gray-light);
		background-color: var(--main-color);
	}
	.btn-text-inv:hover {
		color: var(--main-color);
		background-color: var(--gray-light);
	}
	.bi.bi-chevron-right, .bi.bi-chevron-left, .bi.bi-download {
		font-size: initial;
		margin-left: 5px;
		color: var(--orange-dark);
		-webkit-transition: all 0.4s ease-out;
		-moz-transition: all 0.4s ease-out;
		-o-transition: all 0.4s ease-out;
		transition: all 0.4s ease-out;
	}
	.bi.bi-chevron-left {
		margin-left: 0;
		margin-right: 5px;
		color: var(--orange-dark);
	}
	/*.btn-text:hover > .bi.bi-chevron-right {
		margin-left: 10px;
	}*/

	.btn-footer-card {
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.75px;
		color: var(--main-color);
		transition: all 0.3s ease 0s;
		display: inline-flex;
		justify-content: center;
		align-items: baseline;
		padding: 7px 18px;
		text-decoration: none;
	}
	.btn-footer-card > .bi.bi-download {
		font-size: initial;
		margin-left: 5px;
		color: var(--orange-dark);
		-webkit-transition: all 0.4s ease-out;
		-moz-transition: all 0.4s ease-out;
		-o-transition: all 0.4s ease-out;
		transition: all 0.4s ease-out;
	}
	.btn-footer-card:hover {
		color: var(--orange-dark);
	}
	.accordion .btn{
	    background-color: transparent;
	}

	/*.accordion button.collapsed:before{
	    font-family: "Font Awesome 5 Free";
	    font-size: 0.7rem;
	    font-weight: 900; 
	    content: "\f067";
	    float: left;
	    margin-right: 15px;
	    color: white;
	    border-radius: 50%;
	    padding: 2.5px 6px;
	}
	.accordion button:before{
	    font-family: "Font Awesome 5 Free";
	    font-size: 0.7rem;
	    font-weight: 900; 
	    content: "\f068";
	    float: left;
	    margin-right: 15px;
	    color: white;
	    border-radius: 50%;
	    padding: 2.5px 6px;
	}
	.accordion button.btn-link.collapsed:before{
	    background-color: var(--blue-dark);
	}
	.accordion button.btn-link:before{
	    background-color: var(--blue-dark);
	}*/
	.accordion button.btn-link-red.collapsed:before{
	    background-color: var(--red);
	}
	.accordion button.btn-link-red:before{
	    background-color: var(--red);
	}
	.accordion button.btn-link-orange.collapsed:before{
		background-color: var(--orange-dark);
	}
	.accordion button.btn-link-orange:before{
	    background-color: var(--orange-dark);
	}

	.titulo-primario, .titulo-secundario {
		font-size: 32px;
		font-weight: 700;
		line-height: 1.25;
	}
	.titulo-secundario{
		font-size: 24px;
		color: var(--orange-dark);
		font-weight: 300;
	}
	.subtitulo-primario, .subtitulo-secundario, .content-list .titulo-primario{
		font-size: 24px;
		font-weight: 700;
	}
	.subtitulo-secundario, .content-list .titulo-secundario{
		font-size: 18px;
		color: var(--orange-dark);
		font-weight: 400;
	}
	.content-list .subtitulo-primario{
		font-size: 18px;
	}
	.content-list .subtitulo-secundario{
		font-size: 16px;
	}

	.legenda {
		font-size: .75em;
	}
	.maiusculas {
		text-transform: uppercase;
		font-weight: 500;
	}



	@media only screen and (max-width: 768px){
		.titulo-primario {
			font-size: 26px;
		}
		.titulo-secundario {
			font-size: 22px;
		}
		.subtitulo-primario, .content-list .titulo-primario{
			font-size: 20px;
		}
		.subtitulo-secundario .content-list .titulo-secundario{
			font-size: 18px;
		}
		.content-list .subtitulo-primario{
			font-size: 18px;
		}
		.content-list .subtitulo-secundario{
			font-size: 16px;
		}
	}

	blockquote {
		font-size: 1.2em;
		font-style: italic;
		letter-spacing: .125rem;
		border-left: 5px solid var(--orange-dark);
		background-color: var(--gray-light);
		margin: 20px 0 20px 20px;
		padding: 1rem 1rem 0;
		display: block;
		position: relative;
		overflow: hidden;
	}

	#opinion blockquote {
		font-style: normal;
		font-size: 0.875em;
		letter-spacing: initial;
		background-color: #FFFFFF;
		border-left: inherit;
		padding: 1.75rem;
		margin: 1.75rem;
		border: 4px double var(--orange-dark);
	}

	/*#opinion p {
		margin-top: 0;
		margin-bottom: 0.2rem;
		display: inline;
	}*/

	#opinion .blockquote-footer {
		display: block;
		font-size: 18px;
		color: var(--gray-dark);
		font-weight: 700;
	}
	#opinion span{
		display: block;
		font-size: 0.75rem;
		color: #999999;
		font-weight: 400;
	}

	#opinion .blockquote-footer::before {
		content: "";
	}

	/*#opinion blockquote:before {
		content: '\201C';
		font-family: FontAwesome;
		color: var(--orange-dark);
		font-size: 7.5em;
		font-weight: 700;
		font-size: 4em;
		line-height: .1em;
		margin-right: 0.15em;
		vertical-align: -0.4em;
	}*/

	#opinion blockquote:before,
	#opinion blockquote:after {
		position: absolute;
		color: var(--orange-dark);
		font-size: 8rem;
		width: 4rem;
		height: 4rem;
	}

	#opinion blockquote:before {
		content: '';
		left: 1rem;
		top: -1.5rem;
	}

	/*#opinion blockquote:after {
		content: '”';
		right: -5rem;
		bottom: 1rem;
	}*/

	.limit-5-lines{
	    display: -webkit-box;
	    -webkit-line-clamp: 5;
	    -webkit-box-orient: vertical;
	    overflow: hidden;
	}
	.limit-10-lines{
	    display: -webkit-box;
	    -webkit-line-clamp: 10;
	    -webkit-box-orient: vertical;
	    overflow: hidden;
	}

	.column-count-2{
	    column-count: 2;
	}
	@media (max-width: 576px) {
	    .column-count-2{
	        column-count: 1;
	    }
	}

	.bg-color-gray-light{
	    background-color: var(--gray-light);
	}
	.bg-color-gray{
	    background-color: var(--gray);
	}
	.bg-color-red{
	    background-color: var(--red);
	}
	.bg-color-orange{
	    background-color: var(--orange-dark);
	}
	.bg-color-blue{
	    background-color: var(--blue-dark);
	}
	.bg-color-body{
	    background-color: var(--blue-dark);
	}
	.bg-color-white{
	    background-color: var(--white);
	}
	.bg-color-newsletter{
	    background-color: var(--gray-light);
	}
	.border-blue{
	    border: 2px solid var(--blue-dark);
	}

	.parallax{
		position: relative;
		height: auto;
		background-position: right;
		background-attachment: fixed;
		background: center/cover;
		width: 100%;
	}

	.overlay-img {
	    background: rgba(0, 0, 0, 0.4);
	    width: 100%;
	    height: 100%;
	}

	/* figure .effect-apollo{
	    position: relative;
	    overflow: hidden;
	    min-width: 100%;
	    max-width: 480px;
	    height: auto;
	    background: #975FA3;
	    text-align: center;
	    cursor: pointer;
	} */


	.grid {
		position: relative;
		clear: both;
		margin: 0 auto;
		padding: 1em 0 4em;
		max-width: 1000px;
		list-style: none;
		text-align: center;
	}

	/* Common style */
	.grid figure {
		position: relative;
		float: left;
		overflow: hidden;
		/*margin: 10px 1%;
		min-width: 320px;
		max-width: 480px;
		max-height: 360px;*/
		width: 100%;
		height: auto;
		background: #3085a3;
		text-align: center;
		/* cursor: pointer; */
		aspect-ratio: auto 16/9;
	}

	.grid figure .img {
		position: relative;
		display: block;
		min-height: 100%;
		max-width: 100%;
		opacity: 0.8;
	}

	.grid figure figcaption {
		padding: 2em;
		color: #fff;
		text-transform: uppercase;
		font-size: 1.25em;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	.grid figure figcaption::before,
	.grid figure figcaption::after {
		pointer-events: none;
	}

	.grid figure figcaption,
	.grid figure figcaption > a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.boxshadowtwobottom{
	    position: relative;
	}
	.boxshadowtwobottom:before, .boxshadowtwobottom:after{
	    z-index: -1;
	    position: absolute;
	    content: "";
	    bottom: 28px;
	    left: 22px;
	    width: 50%;
	    top: 80%;
	    max-width:300px;
	    background: #777;
	    -webkit-box-shadow: 0 15px 10px #777;
	    -moz-box-shadow: 0 15px 10px #777;
	    box-shadow: 0 15px 10px #777;
	    -webkit-transform: rotate(-3deg);
	    -moz-transform: rotate(-3deg);
	    -o-transform: rotate(-3deg);
	    -ms-transform: rotate(-3deg);
	    transform: rotate(-3deg);
	}
	.boxshadowtwobottom:after{
	    -webkit-transform: rotate(3deg);
	    -moz-transform: rotate(3deg);
	    -o-transform: rotate(3deg);
	    -ms-transform: rotate(3deg);
	    transform: rotate(3deg);
	    right: 22px;
	    left: auto;
	}

	/*-----------------*/
	/***** Apollo *****/
	/*-----------------*/

	figure.effect-apollo {
		background: var(--white);
	}

	figure.effect-apollo .img {
		opacity: 0.95;
		-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
		transition: opacity 0.35s, transform 0.35s;
		-webkit-transform: scale3d(1,1,1);
		transform: scale3d(1,1,1);
	}

	figure.effect-apollo figcaption::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 130%;
		height: 130%;
		background: rgba(255,255,255,0.5);
		content: '';
		-webkit-transition: -webkit-transform 0.6s;
		transition: transform 0.6s;
		-webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
		transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
	}

	figure.effect-apollo p {
		position: absolute;
		right: 0;
		bottom: 0;
		margin: 3em;
		padding: 0 1em;
		max-width: 150px;
		border-right: 4px solid #fff;
		text-align: right;
		opacity: 0;
		-webkit-transition: opacity 0.35s;
		transition: opacity 0.35s;
	}

	figure.effect-apollo h2 {
		text-align: left;
	}

	figure.effect-apollo:hover .img {
		opacity: 0.6;
		-webkit-transform: scale3d(1.05,1.05,1);
		transform: scale3d(1.05,1.05,1);
	}

	figure.effect-apollo:hover figcaption::before {
		-webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
		transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
	}

	figure.effect-apollo:hover p {
		opacity: 1;
		-webkit-transition-delay: 0.1s;
		transition-delay: 0.1s;
	}


	.loader {
		margin: 0;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.titulo-pp{
		font-size: 2rem;
		font-weight: 700;
	}
	#parceiros_list .row{
		align-items: center;
	}
	#parceiros_list .titulo-pp{
		text-transform: uppercase;
		font-size: 1.375rem;
		font-weight: 700;
	}

	.titulo-pp::after{
		height: 3px;
		width: 4rem;
		content: "";
		display: block;
		background-color: var(--orange-dark);
		margin: auto;
		margin-top: 25px;
	}
	#destaque .titulo-pp::after{
		margin: 0;
		margin-top: 25px;
	} 
	#newsletter .titulo-pp::after{
		margin: 0;
		margin-top: 15px;
		margin-bottom: 15px;
	}
	#newsletter .titulo-pp{
		font-size: 1.5rem;
	} 
	#insights .titulo-pp::after, #noticias .titulo-pp::after, #parceiros .titulo-pp::after, .routes-cards .titulo-pp::after{
		margin-top: 25px;
		margin-bottom: 60px;
	}

	.insights-cards .titulo-pp::after, .news-cards .titulo-pp::after{
		margin-bottom: 2.5rem;
	}
	.subtitulo-pp{
		color: var(--main-color);
		font-weight: 700;
		font-size: 18px;
	}
	#insights .subtitulo-pp, #noticias .subtitulo-pp {
		font-weight: 700;
		margin-bottom: 0.75rem;
		line-height: 1.35;
	}
	#insights .text-pp p, #noticias .text-pp p {
		margin-bottom: 0;
	}
	#insights .text-pp, #noticias .text-pp {
		display: -webkit-box;
		text-overflow: ellipsis;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;
		line-height: 1.5;
	}
	
	#insights-cards .card-title, #noticias-cards .card-title {
		color: var(--main-color);
		font-weight: 700;
		font-size: 18px;
		line-height: 1.35;
		margin-bottom: 0.75rem;
	}
	#insights-cards .card-text, #noticias-cards .card-text {
		line-height: 1.35;
		display: -webkit-box;
		text-overflow: ellipsis;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		overflow: hidden;		
	}

	#parceiros h3.titulo-pp::after{
		margin-top: 25px;
		margin-bottom: 25px;
	}
	#parceiros p {
		line-height: 22px;
	}

	#parceiros .col-image {
		width: 720px;
		height: auto;
		overflow: hidden;
	}

	@media only screen and (max-width : 576px) {
		.titulo-pp{
			font-size: 1.5rem;
			font-weight: 700;
		}
	}

/* DESTAQUE */
    #destaque .mp-mask{
        /*margin: 40px auto;*/
        padding-top: 10rem;
        padding-bottom: 10rem;
    }
    #destaque {
        background-image: url('https://cdn.assec.app/repo/turistrela/website/static/assets/img/banner-destaque.jpg');
        background-size: cover;
    }
    #destaque::after{
        content: '';
        display: block;
        background-image: url('https://cdn.assec.app/repo/turistrela/website/static/assets/img/banner-snow.webp');
        background-position: center;
        width: 100%;
        height: 145px;
        position: absolute;
        left: 0;
        bottom: -2px;
        z-index: 2;
        transform: rotate(180deg);
    }
    #destaque::before{
        content: '';
        display: block;
        background-image: url('https://cdn.assec.app/repo/turistrela/website/static/assets/img/banner-snow.webp');
        background-position: center;
        width: 100%;
        height: 145px;
        position: absolute;
        left: 0;
        top: -2px;
        z-index: 2;
    }
/* end DESTAQUE */

/* NOTÍCIAS */
	#insights .swiper, #noticias .swiper {
		padding: 0.5rem;
	}
    #insights .swiper-wrapper, #noticias .swiper-wrapper {
		margin: 0.5rem auto;
		align-items: flex-start !important;
		display: -ms-flexbox!important;
		/*justify-content: center;*/
		flex-wrap: nowrap;
		flex-direction: row;
	}
/* end NOTÍCIAS */

/* INFORMAÇÕES DE APOIO */
    .card-header {
        padding: .75rem 1.25rem;
        margin-bottom: 0;
        background-color: var(--gray-light);
        border-bottom: 0px;
    }
/* end INFORMAÇÕES DE APOIO */

/* TESTEMUNHOS */
    .swiper-pagination-bullet-active{
        background-color: var(--body);
        /*margin: 0 5px;*/
    }
    .swiper-pagination-bullet{
        margin: 0 2px;
    }
    #opinion .swiper-slide{
        background-color: var(--white);
    }

    .swiper-wrapper{
		align-items: center;
    }
    #opinion {
    	background-color: var(--blue-dark);
    }

    /*section#insights, section#opinion {
		padding: 60px 0;
		overflow: hidden;
		position: relative;
	}

	#insights .section-bg, #opinion .section-bg {
		padding: 6rem 0;
	}
	#insights .section-bg {
		padding-bottom: 0;
	}
	#opinion .section-bg {
		padding-top: 0;
	}
	#insights .section-bg:before, #opinion .section-bg:before {
		content: "";
		background: var(--gray-light);
		position: absolute;
		bottom: 60px;
		top: 60px;
		left: 0;
		right: 0;
		transform: skewY(-3deg);
	}
	#opinion .section-bg:before {
		background: var(--blue-dark);
	}*/


/* end TESTEMUNHOS */


/* SECTIONS */
	/*section#project, section#parceiros {
		margin: 5vh 0;
	}*/
	section#insights {
		background-color: var(--gray-light);
	}
/* end SECTIONS */

/* NEWSLETTER */
	/*#newsletter .form-control{
		width: 100%;
	}*/
	#newsletter .btn-tertiary {
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.75px;
		border-radius: 0 0.25rem 0.25rem 0;
		padding-left: 20px;
		padding-right: 20px;
	}
	#newsletter .form-control {
		border-right: transparent;
		/*height: calc(1.5em + 0.75rem + 8.5px);*/
	}
	#newsletter label {
		font-size: small;
	}
	#newsletter a {
		font-size: small;
		color: #adadad;
	}
/* end NEWSLETTER */

/* SEARCH */
    .limit-lines {
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
/* end SEARCH */


/* PAGINATION */
    .pagination {
        margin: 15px auto;
        display: flex;
        list-style: none;
        outline: none;
    }
    .pagination > .active > a{
        background-color: var(--body);
        border-color: var(--body);
        color: #fff;
    }
    .pagination > li > a{
        /*border: 2px solid var(--body);*/
        padding: 5px 10px;
        margin: auto 5px;
        outline: none;
        cursor: pointer;
    }
    .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
        background-color: var(--blue-dark);
        border-color: var(--blue-dark);
        outline: none;
    }
    .pagination > li > a, .pagination > li > span{
        color: var(--body);
    }
    .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
        border-radius: unset;
    }
/* end PAGINATION */

/* COOKIE */
    .containerCookie {
    	font-size: 0.85rem;
        font-weight: 500;
        /*bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;*/
        background: var(--blue-dark) !important;
        justify-content: center !important;
    }

    .messageCookie {
        text-decoration: none;
        text-align: center;
        padding: 10px 20px;
        display: block;
        color: var(--white);
    }

    .messageCookie p {
        margin: 0;
    }

    .messageCookie a {
        color: var(--blue-light);
        text-decoration: none;
        padding-bottom: 2px;
        border-bottom: rgba(127, 157, 172, 0) solid 1px;
        transition: all .25s ease-out;
    }
    .messageCookie a:hover {
        border-bottom: rgba(127, 157, 172,.5) solid 1px;
    }

    .btn-cookies {
        color: var(--white) !important;
        background-color: var(--orange-dark)  !important;
        /*border: 2px solid var(--white)  !important;
        border-radius: 30px !important;*/
        transition: all .25s ease-out;
    }

    .btn-cookies:hover, .btn-cookies:active, .btn-cookies.active, .open > .dropdown-toggle.btn-cookies,
    .btn-cookies:focus, .btn-cookies.focus{
        color: var(--blue-dark) !important;
        background-color: var(--white) !important;
        outline: none !important;
        /*border-color: var(--orange-dark)  !important;*/
    }
/*end COOKIE */
	.card {
		/*transition: .5s;*/
		border: 1px solid rgba(0,0,0,.05);
		/*box-shadow: 0px 2px 12px rgb(0 0 0 / 8%);*/
	}
	/*.card:hover {}*/


/*#project p, #destaque p, #opinion p {
	font-size: 16px;
}*/



/* NEWS */
	.div-categories {
		display: inline-flex;
		justify-content: flex-end;
		align-items: center;
	}
	.pill-tag {
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: 1px;
		padding-left: 0.25rem;
	}
	.pill-tag:first-child {
		padding-left: 0.25rem;
	}
	.pill-tag:not(:last-child):after {
		content: ', ';
	}
	.pill-tag::first-child:before{
		content: '\F5B0';
	}
	.div-abstract {
		font-size: 1.25rem;
	}

	.card-body{
		padding: 2.14rem;
	}
	/*.card a {
		color: inherit;
	}*/
	.card {
		border-radius: 0;
	}
	.card a:hover {
		text-decoration: none;
	}
	.card-footer {
		background-color: #fff;
		padding-right: 2.14rem;
		padding-left: 2.14rem;
	}
	.routes-cards .card-footer {
		background-color: rgba(0,0,0,.03);
	}
	.bi-tag::before {
		transform: rotate(90deg);
	}
/* end NEWS */

/* PAGE 404 */
	#notfound {
		position: relative;
		height: 100vh;
	}
	#notfound .notfound {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.notfound {
		max-width: 850px;
		width: 100%;
		line-height: 1.4;
		text-align: center;
		padding: 15px;
	}
	.notfound .notfound-404 {
		position: relative;
		height: 220px;
	}
	.notfound .notfound-404 h1 {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		font-size: 186px;
		font-weight: 200;
		margin: 0px;
		background: linear-gradient(130deg, #ffa34f, #ff6f68);
		color:transparent;
		-webkit-background-clip: text;
		background-clip: text;
		text-transform: uppercase;
	}
	.notfound h2 {
		font-size: 33px;
		text-transform: uppercase;
		margin-top: 0px;
		margin-bottom: 25px;
		letter-spacing: 3px;
	}
	.notfound p {
		margin-top: 0px;
		margin-bottom: 25px;
	}
	.notfound a {
		color: #ff6f68;
		text-decoration: none;
		border-bottom: 1px dashed #ff6f68;
		border-radius: 2px;
	}
	.notfound-social>a {
		display: inline-block;
		height: 40px;
		line-height: 40px;
		width: 40px;
		font-size: 14px;
		color: #ff6f68;
		border: 1px solid #efefef;
		border-radius: 50%;
		margin: 3px;
		-webkit-transition: 0.2s all;
		transition: 0.2s all;
	}
	.notfound-social>a:hover {
		color: #fff;
		background-color: #ff6f68;
		border-color: #ff6f68;
	}
	@media only screen and (max-width: 480px) {
		.notfound .notfound-404 {
			position: relative;
			height: 168px;
		}
		.notfound .notfound-404 h1 {
			font-size: 142px;
		}
		.notfound h2 {
			font-size: 22px;
		}
	}
/* end PAGE 404 */


	#insights .insight-item, #noticias .noticia-item, .insights-cards .card, .news-cards .card {
		line-height: 1.35;
		box-sizing: content-box;
		min-height: 200px;
		box-shadow: 0px 2px 12px rgb(0 0 0 / 8%);
		position: relative;
		background: #fff;
		overflow: hidden;
	}

	#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image{
		/*width: 284.75px;
		height: 284.75px;*/
		background-repeat: no-repeat;
		background-color: var(--gray-light);
		/*background-color: red;
		max-height: 50vh;
		min-height: 250px;
		width: 100%;*/
		object-fit: cover;
		object-position: 50% 50%;
		aspect-ratio: auto 3 / 2;
	}

	#insights .insight-img img, #noticias .noticia-img img, .insights-cards .card .div-image, .news-cards .card .div-image {
		min-height: 100%;
		object-fit: cover;
	}

/* LIST */
	/*.div-content {}*/
	.img-list, .img-list2, .img-banner, .img-split {
		background-size: cover;
		background-position: center center;
		width: 350px;
		height: auto;
		aspect-ratio: auto 1 / 1;
		overflow: hidden;
		/*border: 1rem solid var(--white);*/

	}
	.img-banner {
		aspect-ratio: initial;
		width: initial;
	}
	.img-split {
		aspect-ratio: 9 / 16;
		max-width: 25vw;
	}
	.img-list img, .img-list2 img, .img-banner img, .img-split img, #accordionContent .card-body img{
		display: block;
		width: 100%;
		height: 100%;  
		object-fit: cover;
		cursor: -webkit-zoom-in;
		cursor: zoom-in;
	}
	#accordionContent .card-body img {
		background-size: cover;
		background-position: center center;
		width: 350px;
		height: auto;
		aspect-ratio: auto 1 / 1;
		overflow: hidden;
	}
	.img-list a img, .img-list2 a img, .img-banner a img, .img-split a img, #accordionContent .card-body a img {
		opacity: 1;
		transition: opacity 0.35s, transform 0.35s;
		transform: scale3d(1,1,1);
	}
	.img-list a img:hover, .img-list2 a img:hover, .img-banner a img:hover, .img-split a img:hover, #accordionContent .card-body a img:hover {
		opacity: 0.85;
		transform: scale3d(1.025,1.025,1);
	}

	.hover-animate:focus, .hover-animate:hover {
		transform: translate3d(0,-2px,0);
	}

	.hover-animate {
		transition: all .2s ease-in-out;
	}
/* end LIST */	

/* Media Queries */
	@media only screen and (max-width : 1920px) {}
	@media only screen and (max-width : 1680px) {}
	@media only screen and (max-width : 1400px) {}
	@media only screen and (max-width : 1200px) {}
	@media only screen and (max-width : 1180px) {}
	@media only screen and (max-width : 992px) {}
	@media only screen and (max-width : 820px) {}
	@media only screen and (max-width : 768px) {
		.img-list, .img-list2, .img-banner, .img-split{
			aspect-ratio: auto;
			/*box-shadow: none !important;*/
			width: 95%;
			margin-bottom: 3rem;
		}
		blockquote {
			width: auto;
			margin: 1.5rem auto;
		}


		.content-list .col-image .img-banner {
			display: none;
		}
		.content-list .col-image .img-banner:first-child {
			display: block !important;

		}
	}
	@media only screen and (max-width : 576px) {}
/* end Media Queries */

/* Media Print */
	@media print {
		* {
			background: transparent;
			color: #000;
			text-shadow: none; 
			filter: none;
			-ms-filter: none;
			-webkit-print-color-adjust: exact;
			print-color-adjust: exact;
		}
		header, footer, aside, form {
			display: none;
		}
		.page-break	{ display: block; page-break-before: always; }

		.content-list .col-image .img-banner {
			display: none;
		}
		.content-list .col-image .img-banner:first-child {
			display: block !important;
			page-break-inside: avoid;
		}
		img {
			max-width: 100% !important;
			page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
			page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
			page-break-inside: avoid; /* or 'auto' */
			break-inside: avoid;
		}
		ul, img {
			page-break-inside: avoid;
		}
		table, img, svg {
			break-inside: avoid;
		}

		td  { 
			page-break-inside:avoid; 
			page-break-after:auto; 
		}
		p {
		 /* if there aren't at least three lines before the page
		    break, move the element to the start of a new page. */
		    orphans: 3;
		}
	}
/* end Media Print */


/* Media Queries */

	/*@media only screen and (max-width : 1920px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 377.75px;
			height: 377.75px;
		}
	}

	@media only screen and (max-width : 1680px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 324.25px;
			height: 324.25px;
		}
	}
	@media only screen and (max-width : 1400px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 254.75px;
			height: 254.75px;
		}
	}
	@media only screen and (max-width : 1200px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 294px;
			height: 294px;
		}
	}
	@media only screen and (max-width : 1180px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 293px;
			height: 293px;
		}
	}
	@media only screen and (max-width : 992px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 366px;
			height: 366px;
		}
	}
	@media only screen and (max-width : 820px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 238.667px;
			height: 238.667px;
		}
	}
	@media only screen and (max-width : 768px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 340px;
			height: 340px;
		}
	}
	@media only screen and (max-width : 576px) {
		#insights .insight-item .insight-img, #noticias .noticia-item .noticia-img, .insights-cards .card .div-image, .news-cards .card .div-image, .img-list, .img-list2, .img-split{
			width: 332px;
			height: 332px;
		}
	}*/
/* end Media Queries */




/* TABLES */
	.table {
		color: var(--main-color);
		width: auto;
		font-size: small;
		margin: 2rem auto;
		display: contents;
	}
	.table-hover tbody tr:hover {
		color: var(--main-color);
		background-color: var(--gray-light);
	}
/* end TABLES */


/*CARDS EFFECTS*/
	.hoverflowhidden {
		overflow: hidden;
	}

	.card-columns {
		/*-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;*/
		-webkit-column-gap: 1.25rem;
		-moz-column-gap: 1.25rem;
		column-gap: 30px;
		orphans: 1;
		widows: 1;
	}

	.react-pdf__Page__textContent {
		display: none;
	}
/* end CARDS EFFECTS*/

/* FANCYBOX */
	.fancybox__thumbs .carousel__slide .fancybox__thumb::after {
		border-width: 4px !important;
		border-color: #DF760E !important;
		border-radius: 0 !important;
	}
	.fancybox__image, .fancybox__thumb {
		background-color: var(--white) !important;
	}

/* end FANCYBOX */

#opinion .swiper-container .swiper-scrollbar {
	display: none;
}

#opinion blockquote p {
	margin-bottom: 0.5rem !important;
}

#partners-cards .partner-item {
	min-height: initial !important;
}


@media only screen and (max-width : 576px) {
	#opinion blockquote {
		padding: 20px;
		margin: 10px;
	}
	#opinion blockquote:before {
		display: none;
	}
}