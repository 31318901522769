footer .address {
	cursor: default;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}
footer .h6, footer h6 {
    font-size: 1.875rem;
	font-weight: bold;
	line-height: 1.5;
}

footer span, footer a {
	font-size: 0.875rem;
	/*display: flex;
	align-items: baseline;*/
}
footer a:hover {
    text-decoration: none;
    border-bottom: rgba(255, 255, 255,0) solid 1px;
}

footer .redes a:hover {
	text-decoration: none;
    border-bottom: rgba(255, 255, 255,0) solid 1px;
    transform: scale(1.025);
    filter: drop-shadow(2px 2px 2px #011f2f);
}

footer span.logosfooter {
	display: flex;
	align-items: flex-start;
}
span.logos, footer span.logosfooter.redes {
    justify-content: flex-start;
    column-gap: 15px;
}
/*footer .copyright span {
    display: inherit;
}*/

.current_bottom{
    text-decoration: underline;
}

img.light-logo.svg-logo.img-fluid {
	filter: brightness(0) invert(1);
}

.bg-color-body{
    background-color: var(--blue-dark);
}
.bg-color-dark{
    background-color: var(--gray-dark);
}

.footer-bar {
	background-color: var(--gray-dark);
	/* font-size: .75rem; */
	align-items: center;
}

.footer-bar .copyright li span {
    font-size: 12px;
    /*display: inline-flex;
    align-items: center;*/
}

.footer-bar a {
	color: #e03a3c;
	padding-bottom: 0;
    margin-bottom: 0;
	border-bottom: rgba(255, 255, 255, 0) solid 1px;
}
.footer-bar a:hover {
	border-bottom: rgba(255, 255, 255, 0) solid 1px;
}

.copyright {
	line-height: 1.25;
	/*display: inline-flex;*/
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}


@media print {
	.footer-bar {
		position: fixed;
		bottom: 0;
		width: 100%;
		border-top: 1px solid transparent; /* for demo */
		margin-top: 30px;
	}
}