/*.normal .content-body .div-image {
    max-height: 40vh;
    overflow: hidden;
}

.list .div-text, .list2 .div-text {
    display: flow-root;
}*/

/* BLOCKQUOTE */
	blockquote{		
		font-size: 0.875em;
		width:auto;
		margin: 1rem calc(40px + 1rem);
		font-style:italic;
		padding: 1.2em 1.2em 1.2em 3.25em;
		border-left: 8px solid var(--blue-light);
		line-height:1.6;
		position: relative;
		background:var(--gray-light);
	}

	blockquote::before{
		font-family:Arial;
		content: "\201C";
		color:var(--blue-light);
		font-size:4em;
		position: absolute;
		left: 10px;
		top:-10px;
	}

	blockquote::after{
		content: '';
	}

	blockquote span.legenda{
		font-size: smaller;
		display:block;
		font-style: normal;
		font-weight: bold;
		margin-top:1em;
	}

	blockquote p {
		margin-bottom: 0;
	}

	@media only screen and (max-width : 768px) {
		blockquote {
			width: auto;
			margin: 1.5rem auto;
		}
	}
/* end BLOCKQUOTE */

#insights .insight-item, #news-cards .new-item, .insights-cards .card, .news-cards .card {
    box-sizing: content-box;
    min-height: 200px;
    box-shadow: 0px 2px 12px rgb(0 0 0 / 8%);
    position: relative;
    background: #fff;
    overflow: hidden;
}

#insights .insight-item .insight-img, #news-cards .new-item .new-img, .insights-cards .card .div-image, .news-cards .card .div-image {
    background-repeat: no-repeat;
    background-color: var(--gray-light);
    object-fit: cover;
    object-position: 50% 50%;
}

#insights .insight-item .insight-img img, #news-cards .new-item .new-img img, .insights-cards .card .div-image img, .news-cards .card .div-image img{
    object-fit: cover;
    aspect-ratio: 3/2;
}

#insights-cards .card-title, #news-cards .card-title {
    color: var(--main-color);
    font-weight: 800;
    font-size: 18px;
    line-height: 1.35;
}
#partners-cards .card-title, #gallery-cards .card-title, #gallery .card-title {
    font-size: small;
    color: #54595f;
    line-height: 1.35;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.25px;
}

#partners-cards .card-title::before, #gallery-cards .card-title::before {
    height: 2px;
    width: 3rem;
    content: "";
    display: block;
    background-color: var(--orange-dark);
    margin: auto;
    margin-bottom: 20px;
}
#gallery-cards .card-title::before {
    background-color: transparent;
}


#partners-cards .partner-item, #gallery-cards .gallery-item {
    box-sizing: content-box;
    min-height: 200px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    position: relative;
    background: #fff;
    overflow: hidden;
}

#partners-cards .partner-item .partner-img, #gallery-cards .gallery-item .gallery-img {
    background-repeat: no-repeat;
    object-fit: cover;
    object-position: 50% 50%;
    aspect-ratio: auto 4 / 3;
}

#partners-cards .partner-item .partner-img img, #gallery-cards .gallery-item .gallery-img img {
    min-height: 100%;
    object-fit: cover;
}
#partners-cards .hover-animate:focus, #partners-cards .hover-animate:hover, #gallery-cards .hover-animate:focus, #gallery-cards .hover-animate:hover {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

#insights-cards .card-text p, #news-cards .card-text p {
    margin-bottom: 0;
    font-size: 0.875em;
}
#insights-cards .card-text, #news-cards .card-text {
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.insights-cards .titulo-pp::after, .news-cards .titulo-pp::after, .partners-cards .titulo-pp::after, .gallery-cards .titulo-pp::after {
    margin-bottom: 2.5rem;
}
.card-body {
    padding: 1.25rem;
}
.card-footer {
    padding-right: inherit;
    padding-left: inherit;
}

.partners-cards .card-body, .gallery-cards .card-body {
    padding: 0 1.25rem;
}
.partners-cards .card-footer, .gallery-cards .card-footer {
    border: transparent;
    background-color: transparent;
}

.routes-cards .card-footer {
    background-color: rgba(0,0,0,.03);
    border-top: 1px solid rgba(0,0,0,0);
}

.routes-cards .hover-animate:focus, .routes-cards .hover-animate:hover {
    transform: translate3d(0,-2px,0);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
/*.div-image {
    cursor: zoom-in;
}*/
.accordion .content-body .div-image img, .list .content-body .div-image img, .list2 .content-body .div-image img, .normal .content-body .div-image img {
    max-height: 50vh;
    /*min-height: 350px;*/
    width: 100%;
    object-fit: cover;
    object-position: 85% 50%;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}
.normal .content-body .div-image img {
    cursor: default;
}

/*.accordion .div-image, .list .div-image, .list2 .div-image, .normal .div-image {
    border: 1rem solid var(--white);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}*/

.content-body .div-content .title {
    color: var(--cinza-escuro);
    font-weight: 400;
    font-size: 1.20rem;
    text-transform: uppercase;
    position: relative;
    padding-left: 60px;
}

.content-body .div-content .title:after {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 5px;
    width: 3rem;
    background-color: var(--laranja);
    content: "";
}

.content-body .iso-nav button {
    border-radius: 0;
}

.content-body .iso-nav button.active,
.content-body .iso-nav button:hover {
    background-color: var(--cinza-escuro) !important;
    border-color: var(--cinza-escuro) !important;
    outline: none !important;
    color: #FFF !important;
}

.content-body .div-scroll div {
    position: absolute;
    bottom: 20px;
    left: 50%;
    padding-top: 60px;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: var(--cinza);
    text-decoration: none;
    transition: opacity .3s;
}

.content-body .div-scroll div:hover {
    opacity: 0.5;
}

.content-body .div-scroll div span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    border: 2px solid var(--cinza);
    border-radius: 50px;
    box-sizing: border-box;
}

.content-body .div-scroll div span:before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: var(--cinza);
    border-radius: 100%;
    -webkit-animation: scroll-dott 2s infinite;
    animation: scroll-dott 2s infinite;
    box-sizing: border-box;
}

.content-body .div-items .item {
    width: 23%;
    padding: 1%;
    float: left;
}

.content-body .div-items .loading {
    clear: both;
}

.content-list-item {
    border-top: 1px solid #dee2e6;
}


@-webkit-keyframes scroll-dott {
    0% {
        -webkit-transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        -webkit-transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes scroll-dott {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}


/* ACCORDION PAGE */




.element{
    float: right;
    justify-content: center;
    shape-margin: 7em;
}

.accordion .accordion-title {
    display: block;
    position: relative;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    padding-right: 25px;
    cursor: pointer;
}

main.accordion .card-header {
    background-color: var(--gray-light);
    border-bottom: 1px solid #dee2e6;
}

main.accordion .card-header button {
     color: var(--body);
    display: block;
    position: relative;
    font-size: 1em;
    line-height: 24px;
    font-weight: 400;
    padding-right: 25px;
    cursor: pointer;
}

main.accordion .card-header button .bi {
    color: var(--blue-dark);
    font-size: 1.5rem;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

main.accordion .card-header button[aria-expanded="true"] .bi.bi-chevron-down {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

/* END ACCORDION PAGE */

.content-body .div-pdf-view canvas {
    width: 100% !important;
    height: auto !important;
}


/* NOTÍCIAS */
    .div-source {
        font-size: .75rem;
    }
    .div-source span:last-child {
        padding-right: 0;
    }
    /*.div-source span:nth-last-child(-n+1) {
        font-style: italic;
        color: var(--gray);
    }*/
    .div-source span:not(:last-child):after{
        padding: 0 0.5rem;
        color: var(--orange-dark);
        content: "|";
    }

    .div-abstract {
        font-size: 1.1em;
        word-break: break-word;
    }


    .div-gallery .subtitulo-pp{
        color: var(--main-color);
        font-weight: 700;
        font-size: 18px;
        text-align: center;
    }
    .div-gallery .subtitulo-pp::after{
        height: 3px;
        width: 4rem;
        content: "";
        display: block;
        background-color: var(--orange-dark);
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1.25rem;
    }

    .div-gallery img {
        aspect-ratio: 1/1;
        object-fit: cover;
    }

    .div-last {
        position: sticky;
        top: 6rem;
        right: 0;
        z-index: 2;
        height: max-content;
        overflow-y: hidden;
        justify-content: center;
    }
    .div-last .subtitulo-pp{
        color: var(--main-color);
        font-weight: 700;
        font-size: 18px;
        text-align: center;
    }

    .div-last .subtitulo-pp::after{
        height: 3px;
        width: 4rem;
        content: "";
        display: block;
        background-color: var(--orange-dark);
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1.25rem;
    }

    .div-last a {
        color: var(--main-color);
        font-weight: 600;
        font-size: 14px;
    }

    .div-last a:hover{
        text-decoration: none;
    }


    /*.div-last .div-image {}*/

    .div-last .div-image img {
        aspect-ratio: 16 / 9;
        object-fit: cover;
    }

    .div-last .btn-text-inv, .div-last .btn-text-inv a {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.75px;
        display: inline-flex;
        color: var(--gray-light);
        background-color: var(--main-color);
        transition: all 0.3s ease 0s;
        justify-content: center;
        align-items: baseline;
        padding: 7px 14px;
        text-decoration: none;
    }
    .div-last .btn-text-inv:hover, .div-last .btn-text-inv a:hover {color: var(--main-color);
        background-color: var(--gray-light);
    }
    .div-last .div-text{
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
    }
    .div-last .insight-item {
        background-color: #FFF;
    }
    .div-last .div-source {
        font-weight: 400;
    }

    .div-content .div-image img {
        /*aspect-ratio: 16 / 9;*/
        object-fit: cover;
    }

    .insight .div-content .div-image {
        overflow: hidden;
    }
    .insight .div-content .div-image img {
        /*cursor: zoom-in;*/
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    }


    .insight .div-content .div-image img:hover {
        transform: scale3d(1.05,1.05,1), opacity 0.35s, transform 0.35s;
        transition: all .2s ease-in-out;
    }


/* END NOTÍCIAS */
/* PARTNER */
    #partner .div-text p:last-child {
        margin-bottom: 0;
    }
    #partner p a {
        color: var(--main-color);
    }
    #partner p a:hover {
        color: var(--orange-dark);
    }
    #partner .partner-item {
        box-sizing: content-box;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        position: relative;
        overflow: hidden;
    }
    #partner figure {
        aspect-ratio: 1/1;
        overflow: hidden;
    }
    #partner figure img {
        min-height: 100%;
        object-fit: cover;
    }
/* END PARTNER */

/* MEDIA QUERIE */   
    @media only screen and (max-width: 768px){
        .div-last {
            position: initial;
            box-shadow: none;
            height: auto;
            margin-bottom: 30px;
        }
        .div-last .insight-item {
            box-shadow: none !important;
        }
    }
/* END MEDIA QUERY */
