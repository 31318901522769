/* #navbar .navbar{
   background-color: white;
   box-shadow: rgb(0, 0, 0, 0.2) 0px 2px 2px;
} */


.navbar-expand-lg .navbar-nav{
    grid-column-gap: 1.5vw;
    column-gap: 1.5vw;
}
#navbar a{
    font-size: 16px;
    letter-spacing: 0.25px;
    color: var(--gray-dark);
    cursor: pointer;
    transition: all .2s ease-out;
}
#navbar .dropdown-menu a{
    font-size: 14px;
}
#navbar .current_top {
    color: var(--orange-dark);
}

/*#navbar a:last-child {
    padding-right: 0;
}*/
#navbar .menu_superior {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#navbar .menu_superior a {
    font-size: 12px;
    line-height: 0;
    padding: 0 8px;
    font-weight: 400;
}
#navbar .menu_superior a:last-child {
    padding-right: 0;
}
.button_no_border{    
    border: 0px;
    background:transparent;
    cursor: pointer;
}
.button_no_border:focus{    
    border: 0px;
    outline: 0px;
}
.navbar-light .navbar-toggler{
    border: 0;
}

.navbar-light .navbar-toggler:focus{
    outline: 0;
}
#navbar a:hover{
    color: var(--orange-dark);
}

.dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover {
	background-color: var(--gray-light);
}
#navbar a.navbar-brand {
    padding-bottom: 0px;
    border-bottom: 0px solid transparent;
    padding: 0.625rem 1rem;
}
#navbar a.navbar-brand:hover{
    padding-bottom: 0px;
    border-bottom: 0px solid transparent;
}
.svg-logo{
    height: 100px;
    padding: 0.5rem 0;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}

.scroll-navbar .svg-logo{
    height: 56px;
    padding: 0;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}
.navbar-nav{
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;

}

.scroll-navbar .navbar-nav{
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}
.top {
    height: 35px;
    transform: translateY(0);
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
    background-color: var(--gray-light);
}
.scroll-navbar .top{
    transform: translateY(-50px);
    height: 0;
    background-color: transparent;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}
/*.top-bar {
    top: 0;
    height: auto;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}
.scroll-navbar .top-bar{
    position: absolute;
    top: -50px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}*/

/*#small-logo{
    display: none;
}*/
.svg-logo-small{
    height: 45px;
}

/* .nav-effect{
    -webkit-transition: background 1.5s;
    -moz-transition: background 1.5s;
    -ms-transition: background 1.5s;
    -o-transition: background 1.5s;
    transition: background 1.5s;
} */

.h-0-transition{
    height: 0;
    -moz-transition: all 2s ease-in-out;
    -o-transition: all 2s ease-in-out;
    -ms-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out;
}

.dropdown-toggle:after { content: none }


.dropdown-menu {
    border: transparent;
    border-radius: 0;
}

.dropdown-menu.show {
    display: block;
    top: calc(100%);
    padding: 10px 0;
    z-index: 99;
    background: #fff;
    box-shadow: 0px 0px 10px rgb(127 137 161 / 25%);
    transition: 0.3s;
    margin-top: 0;
}

a#navbarDropdown {
    padding-bottom: 0.875rem;
}

@media only screen and (min-width : 992px) {

    /*open dropdown-menu on hover (>992px)*/
    #navbar .navbar-expand-lg .dropdown:hover > .dropdown-menu {
        display: block;
        top: calc(100%);
        padding: 10px 0;
        z-index: 99;
        background: #fff;
        box-shadow: 0px 0px 10px rgb(127 137 161 / 25%);
        transition: 0.3s;
        margin-top: 0;
    }

}

@media (max-width: 1024px) {
    .navbar-expand-lg .navbar-nav{
        column-gap: 15px;
    }
    #navbar .menu_superior a {
        font-size: small;
    }
    /*#navbar a {
        font-size: 14px;
    }*/
	.svg-logo {
		height: 75px;
	}
}

@media (max-width: 992px) {
    #navbar .menu_superior {
        justify-content: flex-end;
    }
    .navbar-collapse{
        background-color: var(--white);
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }

	.dropdown-menu.show {
		top: calc(100%);
		background: transparent;
		box-shadow: none;
        padding-top: 0;
	}
    a#navbarDropdown {
        padding-bottom: 0.5rem;
    }
}

@media (max-width: 820px)  {
    #navbar .navbar-nav a {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }
    #navbar .navbar-nav {
        padding-bottom: 1rem;
    }
    #navbar .dropdown-menu a {
        font-size: 0.875rem;
        }
    }

.bi::before, [class^="bi-"]::before, [class*=" bi-"]::before {
    vertical-align: -0.25em;
}

@media (min-width: 992px){
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0;
		padding-left: 0;
	}
}